import logo from './logo.svg';
import './App.css';
import { useState, useEffect, useRef } from "react";
import { NumericFormat } from 'react-number-format';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import { Layout, Menu, Breadcrumb, Input, Select, Button, Form, InputNumber } from 'antd';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from '@aws-amplify/auth';
import { withAuthenticator } from '@aws-amplify/ui-react';
// import awsExports from './aws-exports';

const awsExports = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_9ylEiu1FZ',
  aws_user_pools_web_client_id: 'ud0jliv2lknblfq3baf4oasq9',
  oauth: {
    domain: 'https://zaffalon-financial.auth.us-east-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code' // or 'token', use 'code' for Authorization code grant
  }
};

Amplify.configure(awsExports);

const { Header, Content, Footer } = Layout;
const { Option } = Select;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function App() {

  const [assets, setAssets] = useState()
  //  => {
  //   fetchDataFromAPI();
  //   const savedValue = localStorage.getItem("assets");
  //   return savedValue !== null ? JSON.parse(savedValue) : [
  //     { name: "Nomad", type: "USD", value: 0 },
  //     { name: "BS2 PJ", type: "USD", value: 0 },
  //     { name: "Inter Invest", type: "BRL", value: 0 },
  //     { name: "Inter", type: "BRL", value: 0 },
  //     { name: "Nubank", type: "BRL", value: 0 },
  //     { name: "T-Cross", type: "asset", value: 0 },
  //     // add more assets here
  //   ];
  // });

  const [netWorthData, setNetWorthData] = useState()
  // () => {
  //   const savedValue = localStorage.getItem("netWorthData");
  //   return savedValue !== null ? JSON.parse(savedValue) : [];
  // });
  const [newAsset, setNewAsset] = useState({ name: "", type: "", value: 0 });
  const [usdbrl, setUsdbrl] = useState(null);
  const [totalDollar, setTotalDollar] = useState(0);
  const [totalReal, setTotalReal] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);
  const [netWorth, setNetWorth] = useState(0);
  const [updateTime, setUpdateTime] = useState(null);
  const [lineData, setLineData] = useState({
    labels: [""],
    datasets: [
      {
        label: "Net Worth",
        data: [1],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1
      }
    ]
  });
  const [session, setSession] = useState(null);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
    fetch("https://economia.awesomeapi.com.br/json/last/USD-BRL")
      .then((response) => response.json())
      .then((data) => {
        const usdbrlValue = data.USDBRL.ask;
        setUsdbrl(usdbrlValue);
      })
      .catch((error) => {
        console.error("Failed to fetch USD-BRL exchange rate:", error);
      });
      fetchDataFromAPI();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      localStorage.setItem("assets", JSON.stringify(assets));
      // saveDataToAPI(assets, netWorthData);
      handleUpdate();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [assets]);


  useEffect(() => {
    let timeoutId = setTimeout(() => {
      handleUpdate();
      saveDayNetWorth(netWorth);
      saveDataToAPI(assets, netWorthData);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [usdbrl, netWorth]);


  useEffect(() => {
    if (netWorthData == undefined)
      return

    setLineData({
      labels: netWorthData?.map((x) => x.updateTime),
      datasets: [
        {
          label: "Net Worth",
          data: netWorthData.map((x) => x.netWorth),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1
        },
        {
          label: "Total Real",
          data: netWorthData.map((x) => x.totalReal),
          fill: false,
          borderColor: "rgb(75, 193, 122)",
          tension: 0.1
        },
        {
          label: "Total Dollar",
          data: netWorthData.map((x) => x.totalDollar),
          fill: false,
          borderColor: "rgb(75, 12, 192)",
          tension: 0.1
        }
      ]
    })
  }, [netWorthData]);


  // save net worth and update time to local storage when net worth changes
  const saveDayNetWorth = (newNetWorth) => {
    console.log(netWorth, updateTime);
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    setUpdateTime(formattedDate)

    if (netWorth !== 0) {
      let savedData = netWorthData
      const data = { netWorth: newNetWorth, totalReal, totalDollar: totalDollar*usdbrl, updateTime };
      const found = savedData.find(x => x.updateTime === updateTime)
      if (found == null) {
        savedData.push(data)
      }
      localStorage.setItem("netWorthData", JSON.stringify(savedData));
      setNetWorthData(savedData);
    }
  }

  const getAuthToken = async () => {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken.toString()
    return idToken;
  };

  const handleUpdate = () => {
    if (assets == null)
      return

    const totalUSD = assets
      .filter((asset) => asset.type === "USD")
      .reduce((sum, asset) => sum + asset.value, 0);

    setTotalDollar(totalUSD);

    const totalBRL = assets
      .filter((asset) => asset.type === "BRL")
      .reduce((sum, asset) => sum + asset.value, 0);
    setTotalReal(totalBRL);

    const totalAssets = assets
      .filter((asset) => asset.type === "asset")
      .reduce((sum, asset) => sum + asset.value, 0);
    setTotalAssets(totalAssets);

    const newNetWorth = (totalUSD * usdbrl) + totalBRL + totalAssets;
    if (usdbrl) {
      setNetWorth(newNetWorth);
    }
  }

  const addNewAsset = () => {
    const newAssets = [...assets];
    newAssets.push(newAsset);
    setAssets(newAssets);
    setNewAsset({ name: newAsset.name, type: newAsset.type, value: 0 });
  }

  // handle input change
  const handleInputChange = (index, value) => {
    const newAssets = [...assets];
    newAssets[index].value = value;
    setAssets(newAssets);
  };

  const handleSelectChange = (value) => {
    setNewAsset({
      ...newAsset,
      ["type"]: value,
    });
  };

  const handleNameChange = (event) => {
    const { value } = event.target
    setNewAsset({
      ...newAsset,
      ["name"]: value,
    });
  };


  const handleDelete = (index) => {
    const newAssets = [...assets];
    newAssets.splice(index, 1);
    setAssets(newAssets);
  };

  const API_URL = 'https://9fpfpmay20.execute-api.us-east-1.amazonaws.com/Financial';

  const saveDataToAPI = async (assets, netWorthData) => {
    if (assets === undefined)
      return

    try {
      const idToken = await getAuthToken();
      const response = await fetch(`${API_URL}/financial-data`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': idToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          assets,
          netWorthData,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  // Function to fetch data
const fetchDataFromAPI = async () => {
  try {
    const token = await getAuthToken();
    const response = await fetch(`${API_URL}/financial-data`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      // Handle 404 (Not Found) separately if needed
      if (response.status === 404) {
        console.log('Data not found.');
        return;
      }
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    setAssets(data.assets ? data.assets.map((asset) => ({ name: asset.name, type: asset.type, value: parseFloat(asset.value)})) : []) ;
    setNetWorthData(data.netWorthData ? data.netWorthData : []);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

  return (
    <Layout>
      <Header>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1">Minhas finanças</Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Minhas finanças</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
          <div style={{ paddingRight: "150px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
              <div style={{ paddingRight: "150px" }}>
                {usdbrl ? (
                  <p>USD-BRL exchange rate: {usdbrl}</p>
                ) : (
                  <p>Loading...</p>
                )}
                <h1>Minhas finanças</h1>
                <div>
                  <h2>Dólar</h2>
                  {assets?.map((asset, index) => {
                    if (asset.type === "USD") {
                      return (<div style={styles.lineItem} key={index}>
                        <label style={styles.labelLine}>{asset.name}: {asset.type === "USD" ? "US$" : "R$"}</label>
                        <InputNumber
                          style={{
                            width: 200,
                          }}
                          value={asset.value}
                          onChange={(event) => handleInputChange(index, event)}
                          step="0.01"
                          min="0"
                          precision={2}
                          decimalSeparator=","
                        />
                        <a href style={{ paddingLeft: "5px" }} onClick={() => handleDelete(index)}>X</a>
                      </div>)
                    }
                  })}
                  <p><b>Total em dólar: US$ {totalDollar.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>
                  <p><b>Total em real: R$ {(totalDollar * usdbrl).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>

                </div>
                <div>
                  <div>
                    <h2>Real</h2>
                    {assets?.map((asset, index) => {
                      if (asset.type === "BRL") {
                        return (<div style={styles.lineItem} key={index}>
                          <label style={styles.labelLine}>{asset.name}: {asset.type === "USD" ? "US$" : "R$"}</label>
                          <InputNumber
                            style={{
                              width: 200,
                            }}
                            value={asset.value}
                            onChange={(event) => handleInputChange(index, event)}
                            step="0.01"
                            min="0"
                            precision={2}
                            decimalSeparator=","
                          />
                          <a href style={{ paddingLeft: "5px" }} onClick={() => handleDelete(index)}>X</a>
                        </div>)
                      }
                    })}
                    <p><b>Total em real: R$ {totalReal.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>
                  </div>
                  <div>
                    <h2>Ativos</h2>
                    {assets?.map((asset, index) => {
                      if (asset.type === "asset") {
                        return (<div style={styles.lineItem} key={index}>
                          <label style={styles.labelLine}>{asset.name}: {"R$"}</label>
                          <InputNumber
                            style={{
                              width: 200,
                            }}
                            value={asset.value}
                            onChange={(event) => handleInputChange(index, event)}
                            step="0.01"
                            min="0"
                            precision={2}
                            decimalSeparator=","
                          />
                          <a href style={{ paddingLeft: "5px" }} onClick={() => handleDelete(index)}>X</a>
                        </div>)
                      }
                    })}
                    <p>Total de ativos: R$ {totalAssets ? totalAssets.toFixed(2) : 0}</p>
                  </div>
                  <div>
                    <h2>Patrimônio líquido</h2>
                    <p><b>Total geral: R$ {(totalDollar * usdbrl + totalReal).toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>
                    <p><b>Total ativos: R$ {totalAssets.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>
                    <p><b>Patrimônio total: R$ {netWorth.toLocaleString(navigator.language, { maximumFractionDigits: 2 })}</b></p>
                  </div>
                  <Button onClick={handleUpdate}>Atualizar</Button>

                  <Form layout="vertical">
                    <h2>Adicionar novo:</h2>

                    <Form.Item label="Name">
                      <Input
                        id="name"
                        type="text"
                        value={newAsset.name}
                        onChange={handleNameChange}
                        placeholder="XYZ Account"
                      />
                    </Form.Item>
                    <Form.Item label="Type">
                      <Select id="type" value={newAsset.type} onChange={handleSelectChange}>
                        <Select.Option value="BRL">BRL</Select.Option>
                        <Select.Option value="USD">USD</Select.Option>
                        <Select.Option value="asset">Asset</Select.Option>
                      </Select>
                    </Form.Item>
                    <Button onClick={addNewAsset} style={{ marginTop: "20px" }}>Add</Button>
                  </Form>

                </div>
              </div>
            </div>
          </div>
          <div style={{ width: '1000px' }}>
            <Line data={lineData} />
          </div>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>My Financial App ©2023 Created by Me</Footer>
    </Layout>
  );
}

const styles = {
  lineItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  labelLine: {
    width: "250px",
  }
}

export default withAuthenticator(App);
